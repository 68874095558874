import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import session from './session';
import postReducer from './post';
import userReducer from './user';
import commententReducer from './comment';
import likeReducer from './like';
import followReducer from './follow';

let enhancer;

const rootReducer = combineReducers({
    session,
    posts: postReducer,
    users: userReducer,
    comments: commententReducer,
    likes: likeReducer,
    follows: followReducer
});


if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require('redux-logger').default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;